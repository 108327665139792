import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import { StaticImage } from "gatsby-plugin-image";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "gatsby";
import NewsletterForm from "../components/newsletter-form";
import { navigate } from "gatsby";
import { TwitterTimelineEmbed } from 'react-twitter-embed';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <div className="w-60 text-center home-intro">
      <div className="intro-container">
        <p>{`The BeYond-COVID(2021–2024) project aimed to make COVID-19 data accessible to scientists in laboratories but also to anyone who can use it, such as medical staff in hospitals or government officials.`}</p>
        <p>{`Going beyond SARS-CoV-2 data, the project aimed to provide a framework for making data from other infectious diseases open and accessible to everyone.`}</p>
        <p>{`Project outcomes are summarised in our `}<a href="https://by-covid.org/pdf/update-elixir-by-covid-brochure-1-7-DIGITAL.pdf" style={{
            "color": "#ffffff",
            "textDecoration": "underline"
          }}>{`Success Stories brochure`}</a>{`.`}</p>
      </div>
      {/* The Survey */}
      {/* The challenge */}
      <h2>{`The challenge`}</h2>
      <p>{`The world has generated vast amounts of data in response to the COVID-19 pandemic, and is still generating more. This data comes from many different sources, and identifying, connecting and integrating it for effective analysis remains a challenge.`}</p>
    </div>
    <div>
      <Container className="lh-sm data-sources big-top-space" mdxType="Container">
  <Row mdxType="Row">
    <Col className="data-source hospitals" mdxType="Col">
      <div>Hospitals</div>
    </Col>
    <Col className="data-source medical" mdxType="Col">Medical Centres</Col>
    <Col className="data-source public" mdxType="Col">Public Health Laboratories</Col>
    <Col className="data-source research-infrastructures" mdxType="Col">
      Research Infrastructures
    </Col>
    <Col className="data-source universities" mdxType="Col">Universities</Col>
  </Row>
  <Row mdxType="Row">
    <Col className="data-source consortia" mdxType="Col">Consortia</Col>
    <Col className="data-source research-centres" mdxType="Col">Research Centres</Col>
  </Row>
      </Container>
      <div className="data-lines text-center fs-6">
        <p>SARS-CoV-2 and other infectious disease data comes from many sources</p>
      </div>
      {/* What we are doing */}
      <div className="bg-semi-opaque what-doing container full-width text-body text-center">
  <div className="w-60 text-center">
          <h2>{`What we are doing`}</h2>
          <div className="what-doing-boxes">
  <div className="box box1 bg-white text-body">
    <h3 className="fs-6 fw-bold mb-1 text-primary">1. Mobilising data</h3>
    <p>
      Ensuring data from around the world can easily be submitted to core data
      hubs.
    </p>
  </div>
  <div className="box box2 bg-white text-body">
    <h3 className="fs-6 fw-bold mb-1 text-primary">2. Connecting data</h3>
    <p>
      Linking infectious disease data. For instance, clinical data with viral
      data.
    </p>
  </div>
  <div className="box box3 bg-white text-body">
    <h3 className="fs-6 fw-bold mb-1 bg-white text-primary">
      3. Standardising data
    </h3>
    <p>
      Providing recommended data management best practices and standards for
      describing data.
    </p>
  </div>
  <div className="box box4 bg-white text-body">
    <h3 className="fs-6 fw-bold mb-1 bg-white text-primary">
      4. Exposing and analysing data
    </h3>
    <p>Providing data analysis methods and protocols.</p>
  </div>
          </div>
          <div className="halo-container">
            <div className="covid-portal">
              <h2 className="visually-hidden-focusable">COVID-19 Portal</h2>
              <p className="mb-0">
  Making the data findable in the European COVID-19 Data Portal, which will
  connect existing virus data resources.
              </p>
              <p className="mb-0">
  The Portal allows entry to open research data and access-controlled patient
  data.
              </p>
              <p className="mb-0 mt-2">
  <a href="/about#data-platform" aria-label="Read more about Data Portal">
    Read more
  </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* The outcomes */}
  <div className="w-60 text-center outcomes-box">
  <div className="outcomes-text">
        <h2 className="mt-3">The outcomes</h2>
        <p>{`The project will make data about SARS-CoV-2 and other viruses easier to access, aggregate and analyse. This will enable scientists to respond faster to new strains of SARS-CoV-2 or to new viruses. It will also help policy-makers assess the impact of the disease and take the most appropriate measures to protect people against new viral diseases.`}</p>
      </div>
    </div>
    <div className="bg-semi-opaque container full-width big-top-space text-body px-4 ">
      <Container className="twitter" mdxType="Container">
  <Row mdxType="Row">
    <Col className="align-items-stretch mb-4 mb-md-0 col-center" xs={12} sm={6} lg={8} mdxType="Col">
      <Card className="mb-4" mdxType="Card">
        <Card.Body className="position-relative">
          <Card.Title as="h3" className="mt-3 fw-bold fs-4 lh-sm">
            About
          </Card.Title>
          <Card.Text className="mb-4 mt-3 px-0">
            Discover the objectives, the structure of the project and the
            project partners.
          </Card.Text>
          <div className="bottom-link-right arrow-right">
            <Link to="/about" mdxType="Link">About</Link>
          </div>
        </Card.Body>
      </Card>
      <Card className="mb-4" mdxType="Card">
        <Card.Body className="position-relative">
          <Card.Title as="h3" className="mt-3 fw-bold fs-4 lh-sm">
            News & events
          </Card.Title>
          <Card.Text className="mb-4 mt-3 px-0">
            See the latest news, events and announcements.
          </Card.Text>
          <div className="bottom-link-right arrow-right">
            <Link to="/news" mdxType="Link">News</Link> & <Link to="/events" mdxType="Link">events</Link>
          </div>
        </Card.Body>
      </Card>
       <Card className="mb-4" mdxType="Card">
        <Card.Body className="position-relative">
          <Card.Title as="h3" className="mt-3 fw-bold fs-4 lh-sm">
            Outreach & media
          </Card.Title>
          <Card.Text className="mb-4 mt-3 px-0">
            The project logos, graphics and outreach activities.
          </Card.Text>
          <div className="bottom-link-right arrow-right">
            <Link to="/outreach-media" mdxType="Link">Outreach & media</Link>
          </div>
        </Card.Body>
      </Card>
                      <h2 className="fs-3 lh-2 mb-2 ps-0 text-dark">Want to learn more?</h2>
      <p className="ps-0 text-dark">Subscribe to our newsletter:</p>
      <NewsletterForm mdxType="NewsletterForm" />
    </Col>
    {/*<Col className="align-items-stretch mb-4 mb-md-0" xs={12} sm={6} lg={3}>
      <Card className="mb-4 home-twitter-box twitter-embed">
         <TwitterTimelineEmbed
          sourceType="profile"
          screenName="BYCOVID_eu"
          options={{height: 660}}
        />
      </Card>
     </Col>*/}
  </Row>
      </Container>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      